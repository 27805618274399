<template>
  <div class="hb_about">
    <div class="hb_about_bg">
      <img :src="basicData.basicBg" alt="" style="width:100%" />
    </div>
    <div class="hb_about_nav" v-if="Object.keys(basicData).length != 0">
      <div class="nav_gsjj">
        <div class="nav_gsjj_tit">
          <h2>{{ basicData.gyList[0].key }}</h2>
          <p>
            <span>{{ basicData.gyList[0].value }}</span>
          </p>
        </div>
        <div class="nav_gsjj_html" v-html="basicData.gy_html"></div>
      </div>
      <div class="nav_gsjj">
        <div class="nav_gsjj_tit">
          <h2>{{ basicData.gyList[1].key }}</h2>
          <p>
            <span>{{ basicData.gyList[1].value }}</span>
          </p>
        </div>
        <div class="nav_ryzz_nav">
          
        </div>
      </div>
      <div class="nav_gsjj">
        <div class="nav_gsjj_tit">
          <h2>{{ basicData.gyList[2].key }}</h2>
          <p>
            <span>{{ basicData.gyList[2].value }}</span>
          </p>
        </div>
        <ul class="nav_qywh_col">
          <li v-for="(item, idx) in basicData.qyList" :key="idx">
            <div class="li_img" v-if="idx != 1">
              <img :src="basicData.qyList2[idx].value" alt="" />
            </div>
            <div class="li_text">
              <h2>{{ item.key }}</h2>
              <span>{{ basicData.qyList2[idx].key }}</span>
              <p>{{ item.value }}</p>
            </div>
            <div class="li_img" v-if="idx == 1">
              <img :src="basicData.qyList2[idx].value" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getPageMsg } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      basicData: {}
    }
  },
  watch: {},
  methods: {
    getData () {
      let that = this
      let id = sessionStorage.getItem(that.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.basic
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.hb_about {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .hb_about_bg {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .hb_about_nav {
    padding: 1rem 0;
    .nav_gsjj {
      width: 80%;
      margin: 0 auto;
      margin-top: 1rem;
      .nav_gsjj_tit {
        text-align: center;
        margin-bottom: 0.5rem;
        h2 {
          font-weight: 600;
          font-size: 40px;
        }
        p {
          position: relative;
          margin-top: 10px;
          span {
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            padding: 6px 15px;
            border: 1px solid #ddd;
            background-color: #fff;
            position: relative;
          }
        }
        p::before {
          position: absolute;
          left: 0;
          top: 50%;
          height: 1px;
          width: 100%;
          background-color: #ddd;
          content: '';
        }
      }
      .nav_gsjj_html {
        line-height: 1.8;
        font-size: 14px;
      }
      .nav_qywh_col {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 33.33%;
          .li_img {
            width: 100%;
            height: 270px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .li_text {
            padding: 40px 30px;
            height: 190px;
            position: relative;
            font-size: 14px;
            background-color: #f8f8f8;
            h2 {
              color: #004fab;
              font-size: 22px;
              margin-bottom: 15px;
            }
            span {
              display: block;
              margin-bottom: 10px;
            }
            p {
              color: #999;
              line-height: 1.4;
            }
          }
          .li_text::after {
            position: absolute;
            left: 35px;
            bottom: 40px;
            content: '';
            width: 30px;
            height: 1px;
            background-color: #004fab;
          }
        }
      }
    }
    .nav_gsjj:nth-child(1) {
      margin-top: 0;
    }
  }
}
</style>
